.with-tooltip {
    position: relative;
    display: inline-block;
    //border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

.with-tooltip-right {
    position: relative;
    display: inline-block;
}

/* Tooltip text */
.with-tooltip .tooltip {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;

    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;
}

/* Tooltip arrow */
.with-tooltip .tooltip::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.with-tooltip-right .tooltip::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
    transform: rotate(0.25turn);

}

.with-tooltip-right .tooltip {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    top: -150%;
    left: 110%;
    margin-left: 10px;

    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;
}

/* Show the tooltip text when you mouse over the tooltip container */
.with-tooltip:not(.show_check_history):hover .tooltip.standard,
.with-tooltip.show_check_history.ticked_by_me:hover .tooltip.check_history,
.with-tooltip-right.show_check_history.ticked_by_me:hover .tooltip.check_history,
.with-tooltip.show_check_history.ticked_by_other:hover .tooltip.check_history,
.with-tooltip-right.show_check_history.ticked_by_other:hover .tooltip.check_history,
.with-tooltip.show_check_history:not(.ticked_by_me):not(.ticked_by_other):hover .tooltip.standard
{
    visibility: visible;
    opacity: 1;
}

.tooltip.check_history {
    font-size: 12px;
    padding-left: 4px;
    padding-right: 4px;
}


.toggle-comments {
    position: relative;
    display: inline-block;
    //border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.toggle-comments .chat_w_team {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;

    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;
}

/* Tooltip arrow */
.toggle-comments .chat_w_team::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.toggle-comments:hover .chat_w_team {
    visibility: visible;
    opacity: 1;
}

div em.fa-chevron-down{
    transform: rotate(-90deg);
    transition: transform 0.2s linear;
}

.fa-chevron-down.open{
    transform: rotate(0deg);
    transition: transform 0.2s linear;
}

a[aria-expanded=true] .fa-chevron-down{
    transform: rotate(0deg);
    transition: transform 0.2s linear;
}

a[aria-expanded=false] .fa-chevron-down{
    transform: rotate(-90deg);
    transition: transform 0.2s linear;
}

.ui-timepicker-container{
    z-index:1151 !important;
}

.datepicker-dropdown .table-condensed td,
.datepicker-dropdown .table-condensed tr{
    padding: 6px;
    text-align: center;
}

.datepicker-dropdown .table-condensed{
    margin: 10px;
}

.datepicker-days td{
    cursor: pointer;
}

.epd-table > tbody > tr > td,
.epd-table > tbody > tr > th,
.epd-table > tfoot > tr > td,
.epd-table > tfoot > tr > th,
.epd-table > thead > tr > td,
.epd-table > thead > tr > th {
    border: 1px solid #454545 !important;
}

.epd-table tr:nth-child(odd) td{
    background-color: #b8c6e6;
}

.epd-table tr:nth-child(even) td{
    background-color: #dbe2f2;
}

.epd-table tr td:first-child{
    background-color: #5372c4 !important;
    color: white;

    width: min-content;
}

.epd-heading td{
    background-color: #5372c4 !important;
    color: white;
}

.row_ticked_by_me{
    background-color: #e8fff2;
    color: black;
}

.row_ticked_by_other{
    background-color: #f6fffa;
    color: black;
}

.content_ticked_by_me,
.content_ticked_by_me .card,
.content_ticked_by_me .card-body{
    background-color: #e8fff2;
}

.content_ticked_by_other,
.content_ticked_by_other .card,
.content_ticked_by_other .card-body{
    background-color: #f6fffa;
}

.row_with_reminder:not(.row_ticked_by_me, .row_ticked_by_other){
    background-color: #f5f9ff;
}

li.ticked_by_me,
li.ticked_by_other,
.container_ticked_by_me .check-wrap,
.container_ticked_by_other .check-wrap{
    background-color: #1eb851;
}

li.ticked_by_me button,
li.ticked_by_other button,
.container_ticked_by_me .check-wrap button,
.container_ticked_by_other .check-wrap button{
    color: white !important;
}

.row_ticked_by_me .bullet,
.row_ticked_by_other .bullet{
    color:green;
}

.table-of-contents h5{
    padding: 10px 10px 10px 20px;
    margin-bottom: 0;
    font-weight: normal;
}

.table-of-contents .tab{
    display: inline-block;
    width: 20px;
}

.table-of-contents .finished{
    background-color: #e8fff2;
    //color: #6dbb5d;
}

.table-of-contents .finished .container_check_wrap{
    background-color: #1eb851;
}

.table-of-contents .finished .container_check_wrap button{
    color: white !important;
}

.table-of-contents .started:not(.finished){
    background-color: #f5f9ff;
    //color: #6287b7;
}

.table-of-contents .container-title-wrap:hover{
    cursor: pointer;
    background-color: #7098c9;
}

.blue-button {
    background-color: $epd_blue;
}

.blue-button em {
    color:white !important;
}

.bullet{
    font-size: 20px;
    line-height: 1;
    float: left;
}

.list-item-text{
    padding-left: 15px;
}

//.modal-open .modal {
//    display:block !important;
//}

.custom-icon-wrap{
    position: relative;
}

.custom-input-icon{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right:10px;
}

.table-bullet{
    font-size: 5px;
    position: absolute;
    top: 50%;
    left:0;
}

.bullet-wrap{
    display: inline-block;
    width: 6px;
    height: 14px;
    position: relative;
}

.reminder-source a{
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    line-height: 23px;
}

.container-title-buttons {
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    padding-right: 23px;
}

.inhalt{
    font-size: 17px!important;
}

[class$='-block']{
    font-size: 16px!important;
}

.container-title-wrap{
    margin-left:0!important;
    margin-right: 0!important;
}
.image-block .image-small {
    max-width: 300px;
}

.image-block .image-medium {
    max-width: 800px;
}

.image-block .image-large {
    max-width: 1500px;
}

.image-block .image-left {
    display: flex;
    justify-content: left;
}

.image-block .image-middle {
    display: flex;
    justify-content: center;
}

.image-block .image-right {
    display: flex;
    justify-content: right;
}

.paragraph-block p,
.paragraph-block ol,
.paragraph-block ul{
    margin-bottom: 6px;
}
