.blue-button{
    background-color: $epd_blue!important;
    color: white!important;
}

.button-row .epd-btn{
    float: right;
}

.epd-btn{
    font-size: 16px;
    padding: 4px 8px;
    border-radius: 4px;
    border:none;
}

.confirmation-modal-title{
    font-size: larger;
}

.confirmation-modal-body{
    margin: auto !important;
}

.confirmation-modal-save{
    background-color: $epd_blue!important;
    color: white!important;
    border-radius: 0!important;
}

.confirmation-modal-close{
    background-color: lightgrey!important;
    color: black!important;
    border-radius: 0!important;
}

.confirmation-modal-text{
    font-size:larger;
    margin: auto;
}

.builder-button{
    display: block;
    width: 40px;
    height: 40px;
    padding: 0;
}

.builder-button.arrow{
    padding-top: 4px;
}
