// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
@import 'admin-lte';
// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';


// libraries
@import '~datatables.net-bs4/css/dataTables.bootstrap4.css';
@import '~datatables.net-buttons-bs4/css/buttons.bootstrap4.css';
@import '~datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css';

@import "node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "node_modules/@fortawesome/fontawesome-free/scss/solid.scss";
@import "node_modules/@fortawesome/fontawesome-free/scss/regular.scss";
@import "node_modules/@fortawesome/fontawesome-free/scss/brands.scss";


//personalized
@import "modules/startpage.scss";
@import "modules/units_page.scss";
@import "shared/sidebar.scss";
@import "modules/forms";
@import "shared/navbar";
@import "comment_box";
@import "block-show";
@import "units_show";
@import "notifications";
@import "shared/general";
@import "shared/operations_index";
