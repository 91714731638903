// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$epd_blue: #355B87;
$link_unselected:#a0a0a0;
$light_green: #a1f9ab;
$outgoing_message: #f4f9ff;
$outgoing_message_border: #b6c8df;
$incoming_message: #f5f5f5;
$incoming_message_border: #e4e4e4;
$send_message_button: #4985b7;
$send_message_button_hover: #67a3d5;
$user_icon: #f0f1f3;
$user_icon_text: #b0b0b2;
$dark-blue: #345b86;
$blue_lighter: #9aadc3;
$blue_light: #6885a4;
$blue_dark: #355c87;
