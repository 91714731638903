.notification-chat-open,
.toggle-question-replies-button{
    position:relative;
    //bottom: px;
    top: -5px;
    font-size: 14px;
    border: 1px solid #b2b2b2;
    border-radius: 40px;
    background: white;
}

.chat-toggle-container,
.toggle-question-replies{
    height: 10px;
}

.comments-area .messaging {
    padding-bottom: 0 !important;
}

.read-notification-button,
.read-question-notification-button {
    position: relative;
    //left: 45px;
    border-radius: 35px;
    width: 25px !important;
    height: 25px !important;
    background-color: $epd_blue!important;
}

.read-all-notifications-button {
    position: relative;
    left: 10px;
    border-radius: 35px;
    width: 30px !important;
    height: 30px !important;
    background-color: $epd_blue!important;
}

.toggle-question-replies-button {
    position:relative;
    top: 5px;
}

.comments-area .messaging .inbox_msg {
    padding-bottom: 0 !important;
    border: none !important;
}


.question-title {
    margin-bottom: 20px;

    .question-title-block {
        display: inline-block;
        max-width: 130ch;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 16px;
        line-height: 0.9;
    }
}

.show-content {
    text-decoration: underline;
}

.show-all-feedback {
    font-weight: bold;
    margin-top: 10px;
    padding: 4px;
    background-color: white;
    font-size: 16px;
}

.question-module-name,
.question-content-container-title {
    font-size: 14px;
    font-weight: bold;
}

.redirect-links{
    color: $epd_blue;
}

.redirect-links:hover{
    color: #163a63;
}

.with-description {
    position: relative;
    display: inline-block;
    //border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tab button styling */
.notification-tab-button {
    min-width: 220px !important;
}

.active-tab-button {
    border: 2px solid red !important;
}

/* Empty notifications text*/
.empty-notification-text {
    font-size: 16px;
    font-weight: bold;
    text-decoration: underline;
}

.tab-navigation {
    margin-bottom: 20px;
}


/* Tooltip text */
.with-description .description-tip {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;

    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;
}

/* Tooltip arrow */
.with-description .description-tip::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.with-description:hover .description-tip {
    visibility: visible;
    opacity: 1;
}


