

.viewport-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.bg-dark-gray {
    background-color: #f8fafc;
}

.functional-description {
    &__title {
        color: #ff0000;
    }
    &__subtitle {
        display: block;
        padding: 5px 10px;
        background-color: $dark-blue;
        color: #ffffff;
    }
    &__block {
        padding: 12px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }
    &__list {
        a {
            color: black;
            display: flex;
            &:hover {
                text-decoration: none;
            }
            &:before {
                content: "\f138";
                margin-right: 9px;
                font-family: "FontAwesome";
                display: inline-block;
            }
        }
    }
}

.house {

    margin: 20px 0;
    color: $blue_dark;

    &__upper {
        color: #fff;
        clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
        &__top {
            padding: 80px 10px 10px;
            text-align: center;
            background-color: $blue_lighter;
        }
        &__bottom {
            padding: 10px 70px 20px;
            text-align: center;
            background-color: $blue_light;
        }
    }

    &__main {
        background-color: $blue_dark;
        color: #fff;
        padding: 20px;

        &__icon {
            font-size: 30px;
            display: block;
            margin-bottom: 8px;

            .fa-xs {padding-top: 6px;}
        }
        &__text {
            line-height: 20px;
        }

        &__title {
            span {
                display: inline-block;
                &::first-letter {
                    font-weight: 600;
                }
            }
        }
        &__left {
            display: grid;
            grid-template-rows: 1fr 1fr 1fr;
            gap: 20px;

            &__icon {
                position: relative;
                padding-top: 100%;
                border-radius: 12px;
                background-color: #274463;
                & > div {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    i {font-size: 36px;}
                }
                &__text {
                    display: block;
                    font-size: 14px;
                    line-height: 14px;
                    margin-top: 8px;
                    word-break: break-word;
                    text-align: center;
                }

            }
        }
        &__right {
            border-radius: 20px;
            display: grid;
            grid-template-rows: 1fr 1fr;
            gap: 30px;
            padding: 60px 40px 10px;
            align-items: center;
            &__block {
                position: relative;


                .fa-phone-volume {
                    transform: rotate(-45deg)
                }
                &__text {
                    display: block;
                    line-height: 20px;
                }
            }
            &__icon {font-size: 72px}
        }
        .file-icon {
            position: absolute;
            left: 10px;
            top: 0;
        }
    }

    .file-icon {
        font-size: 24px;
    }

    .isotext {
        font-size: 14px;
    }
}

.risk-analysis-list {
    font-size: 14px;
    li {
        position: relative;
        &:before {
            content: "";
            position: absolute;
            width: 24px;
            height: 1px;
            background: white;
            left: -25px;
            top: 50%;
        }
        &:first-child::before {
            transform: translateY(-50%) rotate(-30deg);
            transform-origin: right top;
        }
        &:last-child::before {
            transform: translateY(-50%) rotate(30deg);
            transform-origin: right bottom;
        }
    }
}
.data-protection-arrow {
    position: absolute;
    left: 100%;
    top: 30%;
    transform: translate(-50%,-50%);
    font-size: 40px;
    color: rgba(255, 255, 255, 0.3);
}

.arrow-icon {
    padding: 5px 10px;
    border: 1px solid #fff;
    border-right: none;
    display: block;
    text-align: center;
    font-size: 14px;
    line-height: 16px;
    background-color: rgba(255, 255, 255, 0.3);
    position: relative;
    height: 50px;
    width: 125px;
    &__inner {
        position: relative;
        z-index: 1;
    }
    &:after {
        content: "";
        top: -1px;
        width: calc(50px * 0.7);
        height: calc(50px * 0.7);
        position: absolute;
        left: 100%;
        transform: rotate(45deg);
        transform-origin: top left;
        background: rgba(255, 255, 255, 0.3);
        border: 1px solid white;
        z-index: 0;
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%);
    }

}
