.container {
    //max-width: 900px;
    margin: auto;
}

img {
    max-width: 100%;
}

.questions-wrap{
    padding: 0;
}

.questions-box{
    border: 1px dashed #4373ab;
    margin-top: 14px;
    margin-bottom: 14px;
    padding: 15px;
}

.question {
    border-radius: 0px;
    font-size: 14px;
    padding: 10px;
    margin: 0 0 14px;
    background-color: $incoming_message;
    border: 1px solid $incoming_message_border;
    color: #646464;
}

.toggle-replies {
    cursor: pointer;
}

.question .replies {
    background-color: white;
    border-radius: 3px;
    margin-top: 10px;
    color: #646464;
}

.question.asked-by-me {
    background-color: $outgoing_message;
    border: 1px solid $outgoing_message_border;
}

.replies .messaging{
    padding-bottom: 0;
}

.replies .inbox_msg{
    border: none;
}

.inbox_msg {
    border: 1px dashed #c4c4c4;
    clear: both;
    overflow: hidden;
}

.custom-row{
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
}

.message{
    overflow: hidden;
    display: table;
    margin: 5px 0 5px;
    vertical-align: top;
}

.message.switch{
    margin-top: 15px !important;
}

.message_body{
    display: table-row;
}

.received_msg {
    margin-right: auto;
}

.outgoing_msg {
    margin-left: auto;
}

.message_image {
    width: 40px;
    height: 40px;
    text-align: center;
    vertical-align: middle;
    font-size: 20px;
    background-color: $user_icon;
    color: $user_icon_text;
    border-radius: 40px;
    text-transform: uppercase;
    padding: 3px;
}

.notification_image {
    min-width: 25px;
    width: 25px;
    height: 25px;
    text-align: center;
    font-size: 10px;
    background-color: #355b87;
    border-radius: 40px;
    text-transform: uppercase;
    color: white;
    padding-top: 5px;
}

.notification-card {
    background-color: #D3DEEF;
}

.message_text_wrap{
    vertical-align: middle;
    display: table-cell;
    padding-left: 10px;
    padding-right: 10px;
}

.message_text {
    border-radius: 0px;
    font-size: 14px;
    margin: 0;
    padding: 5px 10px 5px 12px;
    width: 50vw;
    color: #3a3a3a;
}

.incoming_msg .message_text {
    text-align: left;
    background: $incoming_message;
    //color: #646464;
    border: 1px solid $incoming_message_border;
}

.outgoing_msg .message_text {
    text-align: right;
    //background: rgba(53, 91, 135, 0.51) none repeat scroll 0 0;
    //color: #fff;
    background: $outgoing_message;
    //color: #3a3a3a;
    border: 1px solid $outgoing_message_border;

}

.notice{
    background: #d4dfed9e;
}

.message_text p{
    margin-bottom: 4px;
}

.new-question,
.type_msg {
    border: 1px solid #c4c4c4;
    position: relative;
}

.question_group input,
.input_msg_write textarea {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    //border: medium none;
    color: #4c4c4c;
    font-size: 15px;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 90%;
    border: none;
    resize: none;
    &:focus,
    &:focus-visible{
        outline: none;
    }
    min-height: 70px !important;
}




.recent_heading h4 {
    color: #05728f;
    font-size: 21px;
    margin: auto;
}

.srch_bar input {
    border: 1px solid #cdcdcd;
    border-width: 0 0 1px 0;
    width: 80%;
    padding: 2px 0 4px 6px;
    background: none;
}

.srch_bar .input-group-addon button {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: medium none;
    padding: 0;
    color: #707070;
    font-size: 18px;
}

.srch_bar .input-group-addon {
    margin: 0 0 0 -27px;
}

.chat_ib h5 {
    font-size: 15px;
    color: #464646;
    margin: 0 0 8px 0;
}

.chat_ib h5 span {
    font-size: 13px;
    float: right;
}

.chat_ib p {
    font-size: 14px;
    color: #989898;
    margin: auto
}

.time_date {
    color: #747474;
    display: block;
    font-size: 12px;
    margin: 8px 0 0;
}

.mesgs {
    float: left;
    padding: 0 15px 15px 15px;
    width: 100%;
    background-color: white;
}

.rsp_send_btn{
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.question_button,
.msg_send_btn{
    width:10%;
    min-width: 100px;
    height: 80%;
    border-radius: 0;
}

.questions-wrap .type_msg {
    border: none;
    border-top: 1px solid #c4c4c4;
}

.questions-wrap .input_msg_write{
    padding: 0 !important;
}

.question_button,
.rsp_send_btn,
.msg_send_btn {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);

    font-size: 17px;
    background: $send_message_button !important;
    color: #fff;

    border: medium none;

    cursor: pointer;

    transition: 0.2s;
}

.question_button:hover,
.rsp_send_btn:hover,
.msg_send_btn:hover {
    background: $send_message_button_hover !important;
}

.messaging {
    padding: 0 0 50px 0;
}

.questions-box{
    background-color: white;
}

.msg_history,
.questions_history{
    height:285px;
    min-height:  285px;
    overflow-y: auto;
}

.rsp_history{
    max-height: 500px;
    padding-top: 20px;
    padding-bottom: 20px;
    overflow-y: auto;
}

.questions_history{
    height: 450px;
}

.msg_history .user_name {
    font-weight: bold;
    color:#000!important;
}

.epd-table {
    font-size: 0.8rem;
}

.sidebar-new-notification{
    position: relative;
    &:after{
        width: 10px;
        height: 10px;
        background: red;
        position: absolute;
        top: 5px;
        left: 10px;
        content: " ";
        border-radius: 20px;
        display: block;
    }
}

.comment-new-messages{
    position: relative;
    background-color: #355B87;
    button{
        color:white;
        em{
            color:white;
        }
    }
    &:after{
        width: 10px;
        height: 10px;
        background: red;
        position: absolute;
        top: -5px;
        left: -5px;
        content: " ";
        border-radius: 20px;
        display: block;
    }
}

.card-header.open_chatbox.toggle-comments{
    width: 100%;
}
