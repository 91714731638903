//$epd_blue: #355B87; <-included in _variables

#module_header{
    margin-top: 40px;
    color: $epd_blue;
    font-weight: 600;
    padding: 10px 10px;
}

.main-sidebar{
    background-color: $epd_blue;
    box-shadow: none!important;
    //border-right: 2px solid #d0d0d0;
    position: fixed;
    max-height: 100%;
    overflow-y: auto;
    position: -webkit-sticky!important;
    position: fixed!important;
    top: 0!important;
}

.main-sidebar::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.main-sidebar::-webkit-scrollbar-track {
    background-color: $epd_blue;
}

.main-sidebar::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.nav-header{
    color: white !important;
}


#abmelden-btn{
    color: white;
}

.nav-link{
    padding: 5px 10px!important;
    text-transform: uppercase;
    color:white !important;
    img{
        margin-right: 5px;
        border: 1px solid #214a7a;
        border-radius: 5px;
        background: white;
        padding: 5px 2px;
        max-height: 30px;
    }
}

.nav-link.active{
    background-color: #355b8721!important;
    color: white!important;
    font-weight: bold!important;
    box-shadow: none!important;
}

.nav-link.selected{
    background-color: #4373ab!important;
}

.nav-link-responsible{
    font-size: 12px !important;
    color:white !important;
    padding-right: 8px;
    padding-left: 8px;
    border-radius: 4px;
}

.nav-link-responsible:hover{
    background-color: #305279 !important;
}

.user_email{
    font-size: 10px;
    white-space: nowrap;
}

.nav-link-custom {
    display: block;
    padding-top: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    color: white;
}

