.form-control{
    border: 1px solid #355B87;
    border-radius: 0;
    background: #f7f7f7;
}

.btn{
    font-weight: normal;
    font-size: 20px;
    padding: 10px;
    border-radius: 10px;
}


.epd-primary{
    border-radius: 0!important;
    background-color: #355B87!important;
    text-transform: capitalize;
}

button.close.epd-secondary,
.epd-secondary{
    border-radius: 0!important;
    background-color: #F4F6F9 !important;
    text-transform: capitalize;
    font-weight: normal;
    font-size: 20px;
    padding: 10px;
    line-height: 30px;

}

