//$epd_blue: #5782b4;

.module-card-image {
    width: 100px;
    max-height: 59px;
}

.module-card {

    border-radius: 15px;
    border-color: $epd_blue;
}

.card-module-title {
    color: $epd_blue;
    text-transform: uppercase;
    width: 10rem;

    p {
        font-weight: bold;
    }
}

.module-progress {
    height: 5px;
}

.module-progress-bar {
    background-color: $epd_blue;

}
