/*.sticky ~ .card[style="order: 1"]{
    margin-top: 200px;
}*/

.sticky {
    position: fixed;
    top: 0;
    left: 251px;
    width: calc(100% - 253px);
    z-index: 13;
}

.sticky .card-body{
    max-height: 400px;
    overflow-y: auto;
}

#stickyheader.sticky {
    -webkit-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
    -moz-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
    box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
}

.no-margin-bottom .breadcrumb{
    margin-bottom: 0 !important;
}

.customer-name{
    margin-top: 1px;
}
.card-body .card-body .card-body {
    padding: 5px;
}

body > div.wrapper > main > div.card.mx-4.mt-4 {
    background: transparent;
    box-shadow: none;
}
.content-accordion .card-header {
    cursor: pointer;
    //background: white;
    padding: 0 10px;
}
//.content-accordion .card .card {
//    margin-bottom: 0!important;
//}

.epd-action-parent{
    border: 1px solid #e9e6e6!important;
    border-radius: 0!important;
    height: 30px;
    margin: 10px 5px;
    padding: 1px!important;
    width: 30px;
    .epd-action-btn{
        font-size: 15px;
        text-align: center;
        width: 100%;
    }
}




.list-block .list-group{
    padding: 0!important;
    margin-top: 3px;
    padding-right: 8px!important;

}

.reminder-modal .modal-body,
.reminder-modal .modal-footer{
    background-color: #F4F6F9;
}

.modal-body{
    color: black;
}

.reminder-modal .modal-content{
    border-radius: 0;
}


.table-bordered th, .table-bordered td {
    border: 1px solid #9ca1a7!important;
}

.content-block{
    width: calc(100% - 133px);
}

.epd-action-buttons{
    position: absolute;
    right: -140px;
    top:0;
}

.list-block > div > .epd-action-buttons ul{
    padding-top: 0!important;
    padding-bottom: 0!important;
}

.list-item-custom .epd-action-buttons .epd-action-parent{
    margin-top:1px;
    margin-bottom: 1px;
    visibility: hidden;
    &:first-child{
        visibility: visible;
    }
    .epd-action-parent {
        border: none!important;
    }
}

.list-item-custom .epd-action-buttons{
    top: -19px;
//    right: -56px;
}

.epd-action-buttons ul{
    float: right;
}

.unit-title-sub{
    font-size: 22px;
}

.unit-subtitle-sub{
    font-style: italic;
    font-size: 22px;
}

.module-title{
    font-size: 22px;
}

.epd-action-buttons li{
    margin-bottom: 0;
    margin-top: 0;
}

.image-block img{
    width: 100%;
//    margin: 0 auto;
    //max-width: 1300px;
    display: block;
}


// reminder

.reminder-action-buttons .epd-action-btn{
    border: 1px solid #cecece;
    border-radius: 0;
    height: 40px;
    width: 40px;
    margin-right: 10px;
}

.reminder-row{
    padding-bottom: 10px;
    border-bottom: 1px solid #cecece;
    margin-bottom: 10px;
    &:last-child{
        padding-bottom: 0;
        border-bottom: none;
        margin-bottom: 0;
    }
}

.reminder-module{
    .card{
        box-shadow: none;
        border-width: 1px;
        border-radius: 0;
    }
    .card-header{
        background-color: $epd_blue;
        padding-right: 30px!important;
        a{
            color:white!important;
            font-size: 23px;
        }
    }
}

.reminder-unit{
    .card{
        box-shadow: none;
        border-width: 1px;
        border-radius: 0;
    }
    .card-header{
        background-color: #c9d6e5;
        padding-right: 10px!important;
        a{
            color:black!important;
            font-size: 20px;
        }
    }
}

.msg_history::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.msg_history::-webkit-scrollbar-track {
    background-color: darkgrey;
}

.msg_history::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}


.questions_history::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.questions_history::-webkit-scrollbar-track {
    background-color: darkgrey;
}

.questions_history::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.rsp_history::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.rsp_history::-webkit-scrollbar-track {
    background-color: darkgrey;
}

.rsp_history::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.customer-urls-table{
    width: 100%;
}

.customer-url-btn{
    width: 100%;
    height: 100%;
    padding: 7px 10px;
    border:none;
    text-transform: lowercase;
    border-radius: 5px;
}

.customer-url-btn::first-letter{
    text-transform: uppercase;
}

.urls-button-column{
    width: 100px;
}

.text-gray-custom,
.text-gray-custom:hover{
    color: #a3a3a3;
}
