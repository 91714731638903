.start_now {
    height: 30px;
    width: 30px;
    background-color: #355B87;
    border-radius: 50%;
    display: inline-block;
}

.started{
    background-color: #355B87;
}

.finished {
    background-color: #28a745;
}

.locked{
    background-color: darkgray;
}

.progress-line.start_now_line {
    background-color: #355B87;
}

.progress-line.started_line{
    background-color: #355B87;
}

.progress-line.finished_line {
    background-color: #28a745;
}

.progress-line{
    background-color: darkgray;
}


.circle-wrap{
    position: relative;
    width: 5vw;
}

.font-normal{
    font-weight: normal;
}

.unit-head{
    display: flex;
}

.unit-image-wrap{
    text-align: left;
    align-self: center;
    width: 5vw;
    height: 100%;
    display: flex;
}

.unit-image-wrap img{
    align-self: center;
}

.unit-title-wrap{
    text-align: left;
    align-self: center;
    padding-left: 10px;
}

.variant_title{
    text-align: left;
    align-self: center;
    padding-left: 10px;
}

.module-subtitle{
    font-weight: normal;
}

tr:not(:last-child) .progress-line{
    z-index: 0;
    position: absolute;
    display: block;
    width: 2px;
    height: calc(100% - 25px);
    left: 26px;
}

.circle-numbers{
    height: 30px;
    width: 30px;
    display: inline-block;
    border-radius: 50%;
    font-weight: bold;
    font-size: 18px;
}

#units_table{
    text-align: left;
}

td{
    text-align: left;
}

.grey-bg{
    //border-color: #355B87;
    color: #355B87;
}

.blue-bg {
    background-color: #355B87;
    border-color: #355B87;
    color: white;
    &:active{
        background-color: #355B87;
        border-color: #355B87;
        color: white;
    }
}

.blue-bg:hover{
    background-color: #627cac;
    color: white;
}

.blue-bg.disabled{
    background-color: #627cac;
    border-color:  #627cac;
}

.blurred{
    background-color: #ddd;
    cursor: not-allowed;
}

.module-unit-btn{
    font-size: 15px!important;
    font-weight: bold!important;
    border: #355B87 solid 2px;
}

.table tr:nth-child(1) td {
    border-top: none;
}

.unit_title{
    display: inline;
}

.unit-end{
    color: white;
}

.unit-content{
    background-color: white;
}

.units_link_disabled{
    pointer-events:none;
}

.content-accordion{
    .card{
        box-shadow: none;
    }

    .card-header{
        cursor:pointer;
    }
}

.module-unit-progress{
    position: absolute;
    bottom: 15px;
    right: 28px;
    font-size: 17px;
    font-weight: normal;
}

.toc-title{
    font-size: 22px;
}

.content-container-header h6 {
    font-size: 20px;
}

.content-container .content-container .content-container-header h6 {
    font-size: 18px;
}

.content-container-header{
    background-color: $epd_blue;
    color: white;
    padding-right: 30px!important;
}

.content-container .content-container .content-container-header{
    background-color: #c9d6e5;
    margin-top: 10px;
    color:black;
    padding-right: 10px!important;
}

.content-container .content-container.card{
    margin-bottom: 1rem!important;
}

.content-container .content-container.first-card .content-container-header{
    margin-top: 0;
}

.unlocked_by_dropdown{
    width: 220px;
    display: block;
    margin-top: 5px;
}

